import React, { useEffect, useState } from 'react'
import { isObjectEmpty, getEmptyFields, randomNumber } from '../../DataUtility'
import { Axios } from '../../config';
import { useLocation } from 'react-router-dom';
import Loader from '../Common/Loader';
import RoomBookedSnackBar from '../Common/RoomBookedSnackBar';

function AddDailyStatus() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Extract values using get method
    const taskID = queryParams.get('taskID');

    const statuses = ["New", "Completed", "Pending"]


    const [loading, setLoading] = useState(false);
    const [emptyField, setEmptyField] = useState('');
    const [bookedRoomNumber, setBookedRoomNumber] = useState("");

    const [formFields, setFormFields] = useState({
        employeeDescription: '',
        assignee: '',
        createdAt: '',
        // Add more form fields as needed
    });
    const [empolyees, setEmpolyees] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);


    useEffect(() => {
        Axios.get('employees')
            .then((res) => {
                setEmpolyees(res.data.Data);
            });

        // Axios.get('projects')
        //     .then((res) => {
        //         setProjects(res.data.Data);
        //     });

        return () => {
        };
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormFields({
            ...formFields,
            [name]: value,
        });

        //setting empty field name to state to render error messages
        if (value === '') {
            setEmptyField(name)
        }
        else {
            setEmptyField("")
        }
    };

    const handleSubmit = (e) => {
        // on submitting form check for empty fields set empty filed name to state
        formFields.createdAt = new Date().toISOString()
        if (isObjectEmpty(formFields) === true) {
            let emptyFieldName = getEmptyFields(formFields)
            setEmptyField(emptyFieldName[0])
        }

        if (isObjectEmpty(formFields) === false) {
            setLoading(true);

            Axios.post('add-employee-status', formFields)
                .then((res) => {
                    openModal();

                }).catch(() => {
                    console.error("Failed to request");
                })
                .finally(() => {
                    setFormFields({
                        employeeDescription: '',
                        assignee: '',
                        createdAt: '',
                        // Add more form fields as needed
                    });
                    setLoading(false);
                })


        };
        e.preventDefault();
    }

    const openModal = () => {
        setModalOpen(true);
        // Disable scrolling
        document.body.style.overflow = 'hidden';
    };


    const closeModal = () => {
        setModalOpen(false);
        // Enable scrolling
        document.body.style.overflow = 'auto';
    };
    return (<>
        {loading ? <Loader /> :
            <section id="booking">
                <RoomBookedSnackBar isModalOpen={modalOpen} closeModal={closeModal} roomNo={bookedRoomNumber} msg={"Your Status Added"} redirect='employee-daily-status' />
                <h2>Add Daily Task</h2>
                <div className="textarea-wrapper">

                    <label htmlFor="employeeDescription">Description:</label>
                    {/* <input type="text" id="employeeDescription" name="employeeDescription" required value={formFields.employeeDescription} onChange={handleInputChange} /> */}
                    <textarea id="employeeDescription" name="employeeDescription" required value={formFields.employeeDescription} onChange={handleInputChange} />
                    {emptyField === 'employeeDescription' && <span className='error-msg'>Please Fill Description</span>}

                </div>
                <div className="status-wrapper">

                    <div className="task-assignee">

                        <label htmlFor="assignee">Employee:</label>
                        <select id="assignee" name="assignee" required value={formFields.assignee} onChange={handleInputChange}>
                            <option value="" disabled>Select employee</option>
                            {empolyees.map((value, index) => {
                                return <option key={index} value={value.Name}>{value.Name}</option>
                            })}

                        </select>
                        {emptyField === 'assignee' && <span className='error-msg'>Please Select Assignee </span>}
                    </div>
                </div>

                <button onClick={handleSubmit}>Add</button>
            </section >
        }
    </>)

}

export default AddDailyStatus;