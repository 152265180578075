import React, { useEffect, useState } from 'react'
import RoomCard from './TaskCard';
import { Axios } from '../../config';
import { useNavigate } from 'react-router-dom';
import Loader from '../Common/Loader';
import NoDataFound from '../Common/NoDataFound';

function Welcome() {


    return (<>
        
            <div>
                <section className='availability'>
                    <div className="welcome-page-wrapper">

                    <h1>Welcome to Task management system</h1>
                    <br />
                    <h3>Please Navigate to abover Urls.</h3>

                    </div>
                </section>

            </div>
    </>)

}

export default Welcome;