
const idsList = [];

export function isObjectEmpty(obj) {

    for (let key in obj) {
        if (isNullOrEmpty(obj[key])) {
            return true; // At least one non-empty value found
        }
    }
    return false; // All values are empty or falsy
}

export function getEmptyFields(obj) {
    const emptyFields = [];

    for (const key in obj) {
        if (obj.hasOwnProperty(key) && !obj[key]) {
            emptyFields.push(key);
        }
    }

    return emptyFields;
}

function isNullOrEmpty(str) {
    let newStr = str.toString();
    return newStr === null || newStr.trim() === '';
}

export function isPhoneNumberValid(phoneNumber) {
    // Regular expression for North American phone numbers
    const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

    return phoneRegex.test(phoneNumber);
}

export const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
};

export const randomNumber = (n) => {
    let array = [];

    for (let i = 0; i < n; i++) {
        array.push(Math.floor(Math.random() * 10));
    }
    return array.join('');
}

export function getCurrentDate(today) {
    // const today = new Date();
    const day = String(today.getDate()).padStart(2, '0'); // Get day and pad with leading zero if necessary
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Get month (zero-based index) and pad with leading zero if necessary
    const year = today.getFullYear(); // Get full year
    return `${day}/${month}/${year}`;
}

// dd-mm-yyyy
export function convertDateFormat(dateStr) {
    // Split the date string by the '-' delimiter
    var parts = dateStr.split("-");

    // Create a new Date object with the year, month, and day
    var date = new Date(parts[0], parts[1] - 1, parts[2]); // Month is 0-indexed, so subtract 1 from the month

    // Get the day, month, and year components from the Date object
    var day = date.getDate().toString().padStart(2, "0"); // Ensure two-digit day
    var month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed, so add 1
    var year = date.getFullYear();

    // Concatenate the components in the desired format
    var formattedDate = day + "-" + month + "-" + year;

    return formattedDate;
}

export function getLeaveDateAndDurationByAssignee(assigneeName, objectList) {
    // Iterate through the object list
    const leaveDates = []; // Array to store leave dates

    // Iterate through the object list
    for (const obj of objectList) {
        // Check if assignee name matches
        if (obj.assignee === assigneeName) {
            leaveDates.push({ leaveDate: obj.leaveDate, leaveCount: obj.leaveDuration });
        }
    }
    return leaveDates;
}

    export function getLeaveDateByAssignee(assigneeName, objectList) {
        // Iterate through the object list
        for (const obj of objectList) {
            // Check if assignee name matches
            if (obj.assignee === assigneeName) {
                return obj.leaveDate; // Return leave date if found
            }
        }
        return null; // Return null if assignee not found
    }