import React from "react";
import { Link } from 'react-router-dom';

function RoomBookedSnackBar({ isModalOpen, closeModal, roomNo , msg, redirect = "task-filter"}) {

    // const [modalOpen, setModalOpen] = useState(true);

    // const openModal = () => {
    //     setModalOpen(true);
    //     // Disable scrolling
    //     document.body.style.overflow = 'hidden';
    // };


    // const closeModal = () => {
    //     setModalOpen(false);
    //     // Enable scrolling
    //     document.body.style.overflow = 'auto';
    // };
    return (<>
        {isModalOpen && <div className="snack-bar-wrapper" onClick={closeModal}>
            <div className="modal-content">
                <span className="close" onClick={closeModal}>&times;</span>
                <p>{msg}</p>
                <hr />
                <Link to={`/${redirect}`} className="link" onClick={closeModal}><p style={{ fontSize: "15px" }} >Jump To Tasks</p></Link>
            </div>
        </div>
        }

    </>)
}

export default RoomBookedSnackBar;