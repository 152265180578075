import React, { useEffect, useRef, useState } from "react";
import RoomCard from "../TaskByDay/TaskCard";
import { Axios } from "../../config";
import Loader from "../Common/Loader";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../Common/NoDataFound";
import EmployeeStatusCard from "../TaskByDay/EmployeeStatusCard";
import { getCurrentDate } from "../../DataUtility";


function EmployeeStatus() {


    const [taskList, setTaskList] = useState([]);
    const [filterTaskList, setFilterTaskList] = useState([]);
    const [dateFilterTaskList, setDateFilterTaskList] = useState([]);
    const [baseUrl, setBaseUrl] = useState("");
    const statuses = ["New", "Completed", "Pending", "In-Process"]
    const [empolyees, setEmpolyees] = useState([]);

    // const [projects, setProjects] = useState([]);
    // const statusRef = useRef(null);
    // const assigneeRef = useRef(null);

    const [formFields, setFormFields] = useState({
        assignee: 'All',
        date: "",
        taskID: "",
        // Add more form fields as needed
    });
    // const [bookedRoomList, setBookedRoomList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        Axios.get('employee-statuses')
            .then((res) => {
                setFilterTaskList(res.data.Data);
                setTaskList(res.data.Data);
                setDateFilterTaskList(res.data.Data);
            })
            .catch(() => {
                console.error("Errro when Fetching Room Details")
            })
            .finally(() => {
                setLoading(false);
            })
        const baseUrl = window.location.protocol + '//' + window.location.host;
        setBaseUrl(baseUrl);

        Axios.get('employees')
            .then((res) => {
                setEmpolyees(res.data.Data);
            });

        // Axios.get('projects')
        //     .then((res) => {
        //         setProjects(res.data.Data);
        //     });

        return () => {
        };
    }, [false]);

    const handleClick = (id) => {
        window.location = `${baseUrl}/update/?taskID=${id}`;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name == "assignee") {
            // setFormFields(formFields.status = "All")
            let newTaskList = dateFilterTaskList.filter((itemValue) => {
                if (value == "All") {
                    return itemValue.employee_name;
                } else {
                    return itemValue.employee_name == value;
                }
            })
            setFilterTaskList(newTaskList);

        } else if (name == "date") {
            let dateStr;
            let dateTime = new Date();
            let today = dateTime.getDate();
            let newTaskList;
            if (value == "0") {
                dateTime.setDate(today - 0);
                dateStr = dateTime.toISOString().split("T")[0];
                console.log("now", getCurrentDate(dateTime));
                newTaskList = taskList.filter((itemValue) => {
                    console.log("dataabase", getCurrentDate(new Date(itemValue.createdAt)));
                    return getCurrentDate(new Date(itemValue.createdAt)) == getCurrentDate(dateTime);
                });

            } else if (value == "1") {
                dateTime.setDate(today - 1);
                dateStr = dateTime.toISOString().split("T")[0]
                console.log("now", getCurrentDate(dateTime));
                newTaskList = taskList.filter((itemValue) => {
                    console.log("dataabase", getCurrentDate(new Date(itemValue.createdAt)));
                    return getCurrentDate(new Date(itemValue.createdAt)) == getCurrentDate(dateTime);
                });
            }
            else if (value == "7") {
                dateTime.setDate(today - 7);
                dateStr = dateTime.toISOString().split("T")[0]
                newTaskList = taskList.filter((itemValue) => {
                    console.log("dataabase", getCurrentDate(new Date(itemValue.createdAt)));
                    return new Date(itemValue.createdAt) >= dateTime;
                });

            }
            else if (value == "All") {

                newTaskList = taskList.filter((itemValue) => {
                    return itemValue.createdAt;
                    //             const currentDate = getCurrentDate(dateTime);
                    // const TaskDate = getCurrentDate(new Date(itemValue.createdAt));
                    // return currentDate <= TaskDate;
                })
            }

            setFilterTaskList(newTaskList);
            setDateFilterTaskList(newTaskList);
            setFormFields(formFields.assignee = "All")
        }





        setFormFields({
            ...formFields,
            [name]: value,
        });
    };

    return (<>
        {loading ? <Loader /> :
            <section className='availability'>
                <h2>Employee Status</h2>

                <div className="status-wrapper-filter">
                    <div className='task-status'>
                        <label htmlFor="date">Date:</label>
                        <select id="date" name="date" required value={formFields.date} onChange={handleInputChange}>
                            <option value="All">All</option>
                            <option value="0">Today</option>
                            <option value="1">Yesterday</option>
                            <option value="7">7 Days</option>
                            {/* {statuses.map((value, index) => {
                                return <option key={index} value={value}>{value}</option>
                            })} */}
                        </select>
                    </div>

                    <div className="task-assignee">

                        <label htmlFor="assignee">Empolyee:</label>
                        <select id="assignee" name="assignee" required value={formFields.assignee} onChange={handleInputChange}>
                            <option value="All">All</option>
                            {empolyees.map((value, index) => {
                                return <option key={index} value={value.Name}>{value.Name}</option>
                            })}

                        </select>
                    </div>

                </div>

                <div className="room-card-wrapper">

                    {/* {filterTaskList.length == 0 && taskList.map((task, index) => {
                        return <RoomCard key={index} object={task} handleClick={handleClick}/>
                    })} */}
                    {filterTaskList.length != 0 ? filterTaskList.sort((a, b) => {return new Date(b.createdAt) - new Date(a.createdAt)}).map((task, index) => {
                        return <EmployeeStatusCard key={index} object={task} handleClick={handleClick} />
                    }) : < NoDataFound />}


                </div>
            </section>
        }
    </>)
}

export default EmployeeStatus;