import React, { useEffect, useState } from "react";
import LeaveForm from "./LeaveForm";
import { Axios } from "../../config";
import LeaveStatusCard from "./LeaveStatusCard";
import Loader from "../Common/Loader";
import { useSelector } from "react-redux";
import ApproveLeave from "./ApproveLeave";
import DateBox from "./DateBox";
import EmployeeBox from "./EmployeeBox";

const LeavePalns = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leavesList, setLeavesList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [approveList, setApproveList] = useState([])
  const [baseUrl, setBaseUrl] = useState("");
  const [isapprovePopupOpen, setApprovePopupOpen] = useState(false)
  const isAdmin = useSelector((state) => state.userReducer.admin);
  const [empolyees, setEmpolyees] = useState([]);

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const dateRange = [0, 1, 2, 3, , 4, 5, , 6, 7, 8, 9, 10]

  useEffect(() => {
    // Update approveList whenever leavesList changes
    setApproveList(leavesList.filter((leave) => {
      return leave.status === "Unapproved" && leave.isActive === 1;
    }));
    Axios.get('employees')
      .then((res) => {
        setEmpolyees(res.data.Data);
      });

    // Axios.get("leave-statuses")
    //   .then((res) => {
    //     // openModal()
    //     setLeavesList(res.data.Data);
    //     setFilterList(res.data.Data);
    //   })
    //   .catch(() => {
    //     console.error("Errro when Fetching Room Details");
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  }, [true]);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const openApprovePopUp = () => {
    closeDialog();
    setApproveList(leavesList.filter((leave) => {
      return leave.status === "Unapproved" && leave.isActive === 1
    }))
    setApprovePopupOpen(true);
  }
  const closeApprovePopUp = () => {
    setApprovePopupOpen(false);
  }
  useEffect(() => {
    setLoading(true);
    Axios.get("approved-leaves")
      .then((res) => {
        // openModal()
        setLeavesList(res.data.Data);
        setFilterList(res.data.Data);
      })
      .catch(() => {
        console.error("Errro when Fetching Room Details");
      })
      .finally(() => {
        setLoading(false);
      });
    Axios.get("employees").then((res) => {
      setEmployees(res.data.Data);
    });
    const baseUrl = window.location.protocol + "//" + window.location.host;
    setBaseUrl(baseUrl);
  }, []);

  const handleClick = (e) => {
    if (e === 1) {
      // Handle click for "Coming Today" button
      setFilterList(
        leavesList.filter((leave) => {
          const leaveDate = new Date(leave.leaveDate);
          const today = new Date();

          // Calculate end date by adding leave duration to leave date
          const endDate = new Date(leaveDate);
          endDate.setDate(endDate.getDate() + leave.leaveDuration);

          console.log(endDate.getDate()); // Output the day of the end date

          // Check if the end date is equal to today's date
          return today.getDate() === endDate.getDate() && leave.isActive;

          // Compare the end date of the leave with today's date
          // return arrivalDate.getTime() === today.getTime();
        })
      );
    } else if (e === 2) {
      // Handle click for "Leave from Today" button
      setFilterList(
        leavesList.filter((leave) => {
          // Get the current date
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth() + 1; // Month is zero-based
          const currentDay = currentDate.getDate();

          // Split the date string by the '-' delimiter
          const parts = leave.leaveDate.split("-");
          const year = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10);
          const day = parseInt(parts[2], 10);
          // Check if the date components match today's date
          return (
            year === currentYear && month === currentMonth && day === currentDay && leave.isActive === 1
          );
        })
      );
    } else if (e === 3) {
      setFilterList(leavesList.filter(leave => {
        return leave.isActive === 1;
      }));
    }
  };


  // const openModal = () => {
  //   setModalOpen(true);
  //   // Disable scrolling
  //   document.body.style.overflow = "hidden";
  // };

  // const closeModal = () => {
  //   setModalOpen(false);
  //   // Enable scrolling
  //   document.body.style.overflow = "auto";
  // };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section id="leave-journey-section">
          <div className="leave-journey">
            <div className="date-row">
              <div className=""><pre>           </pre></div>

              {dateRange.map((value, index) => {

                return <DateBox key={index} lastDate={value} />
              })}
            </div>
            <div className="employee-column">
              <div className="row">
                {employees.map((value, index) => {
                  return <EmployeeBox key={index} employeeName={value.Name} leavesList={leavesList} />
                })}
              </div>
              {/* <div className="row">{dateRange.map((value, index) => {

                return <DateBox key={index} lastDate={value} />
              })}</div> */}
            </div>
          </div>


          {/*           
          <div className="date-range welcome-page-wrapper">
            {dateRange.map((value, index) =>{

              return <DateBox key={index} lastDate={value} />
            })}
          </div>

          <div className="employee-wrapper">
            {monthNames.map((value, index) =>{

              return <div key={index} className="employee-cell"> {value} </div>
            })}
          </div> */}
        </section>
      )}
    </>
  );
};

export default LeavePalns;
