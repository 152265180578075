import React, { useEffect, useState } from "react";
import RoomCard from "../TaskByDay/TaskCard";
import { Axios } from "../../config";
import Loader from "../Common/Loader";

import NoDataFound from "../Common/NoDataFound";
import ConfirmDialogBox from "../Common/ConfirmDialogBox";
function TaskFilter() {


    const [taskList, setTaskList] = useState([]);
    const [filterTaskList, setFilterTaskList] = useState([]);
    const [baseUrl, setBaseUrl] = useState("");
    const statuses = ["New", "Completed", "Pending", "In-Process"]
    const [empolyees, setEmpolyees] = useState([]);
    const [isModelOpen, setIsModelOpen] = useState(false);
    // const [isOkClicked, setOkClicked] = useState(false);
    const [itemTaskId, setItemTaskId] = useState("");



    const [formFields, setFormFields] = useState({
        task_title: '',
        task_description: '',
        assignee: '',
        project_name: '',
        status: "",
        taskID: "",
        updatedAt: ""
        // Add more form fields as needed
    });
    // const [bookedRoomList, setBookedRoomList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        Axios.get('tasks')
            .then((res) => {
                setFilterTaskList(res.data.Data);
                setTaskList(res.data.Data);
            })
            .catch(() => {
                console.error("Errro when Fetching Room Details")
            })
            .finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 2000)

            })
        const baseUrl = window.location.protocol + '//' + window.location.host;
        setBaseUrl(baseUrl);

        setLoading(true);
        Axios.get('employees')
            .then((res) => {
                setEmpolyees(res.data.Data);
            });

        return () => {
        };
    }, []);

    const handleClick = (id) => {
        window.location = `${baseUrl}/update/?taskID=${id}`;
    };

    const deleteTask = () => {
        // let tempFilterList = filterTaskList;
        // let tempTaskList = taskList;
        setLoading(true);
        Axios.post('delete-task', { taskID: itemTaskId })
            .then(() => {
                // console.log("deleted Succesfully");
                // console.log("before Deleteting Item", tempFilterList);
                // let index = tempFilterList.findIndex(obj => obj["taskID"] == itemTaskId);
                // let deletedItem = tempFilterList.splice(index, 1)
                // console.log("deleteItemFilter:", deletedItem);
                // // setFilterTaskList(tempFilterList);
                // console.log("after deleting Item :", tempFilterList);


                // console.log("before Deleteting Item", tempTaskList);

                // let taskIndex = tempTaskList.findIndex(obj => obj["taskID"] == itemTaskId);
                // tempTaskList.splice(index, 1)
                // console.log(tempTaskList.length);
                // // setTaskList(tempTaskList);
                // console.log("deleteItemFilter:", deletedItem);
                // // setFilterTaskList(tempFilterList);
                // console.log("after deleting Item :", tempTaskList);

                // console.log(itemTaskId);

                window.location.reload();

            })
            .catch(() => {
                console.error("error while deleting task.");
            })
            .finally(() => {
                // setLoading(false);
            })
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // let assignee = formFields.assignee;
        // let status = formFields.status;
        if (name === "status") {
            setFormFields(formFields.assignee = "All")
            let newTaskList = taskList.filter((itemValue) => {
                if (value === "All") {
                    return itemValue.status
                }else {
                    return itemValue.status === value
                }
            })
            setFilterTaskList(newTaskList);
        }

        else if (name === "assignee") {
            setFormFields(formFields.status = "All")
            let newTaskList = taskList.filter((itemValue) => {
                if (value === "All") {
                    return itemValue.assignee;
                } else {
                    return itemValue.assignee === value;
                }
            })
            setFilterTaskList(newTaskList);
        };




        setFormFields({
            ...formFields,
            [name]: value,
        });
    };

    // function preventScrolling(event) {
    //     event.preventDefault();
    // }

    const openModal = (id) => {
        setItemTaskId(id);
        setIsModelOpen(true)
        // Disable scrolling
        document.body.style.overflow = 'hidden';
        // Add event listeners to prevent scrolling
        // document.addEventListener('wheel', preventScrolling, { passive: false });
    };


    const closeModal = () => {
        setIsModelOpen(false)
        // setModalOpenForDescription(false);
        // setTitleModalOpen(false);
        // Enable scrolling
        document.body.style.overflow = 'auto';
        // document.removeEventListener('wheel', preventScrolling, {passive: true});
    };

    return (<>
        <ConfirmDialogBox isModalOpen={isModelOpen} closeModal={closeModal} content={"Do you want to delete the Task?"} handleOk={deleteTask} handleNO={closeModal} />
        {loading ? <Loader /> :
            <section className='availability'>
                <h2>All</h2>

                <div className="status-wrapper-filter">
                    <div className='task-status'>
                        <label htmlFor="status">Status:</label>
                        <select id="status" name="status" required value={formFields.status} onChange={handleInputChange}>
                            <option value="All">All</option>
                            {statuses.map((value, index) => {
                                return <option key={index} value={value}>{value}</option>
                            })}
                        </select>
                    </div>

                    <div className="task-assignee">

                        <label htmlFor="assignee">Assignee:</label>
                        <select id="assignee" name="assignee" required value={formFields.assignee} onChange={handleInputChange}>
                            <option value="All">All</option>
                            {empolyees.map((value, index) => {
                                return <option key={index} value={value.Name}>{value.Name}</option>
                            })}

                        </select>
                    </div>

                </div>

                <div className="room-card-wrapper">

                    {/* {filterTaskList.length == 0 && taskList.map((task, index) => {
                        return <RoomCard key={index} object={task} handleClick={handleClick}/>
                    })} */}
                    {filterTaskList.length !==0 ? filterTaskList.map((task, index) => {
                        return <RoomCard key={index} object={task} handleClick={handleClick} deleteItem={openModal} />
                    }) : < NoDataFound />}


                </div>
            </section>
        }
    </>)
}

export default TaskFilter;