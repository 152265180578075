import React, { useEffect, useState } from 'react'
import { isObjectEmpty, getEmptyFields, randomNumber } from '../../DataUtility'
import { Axios } from '../../config';
import { useLocation } from 'react-router-dom';
import Loader from '../Common/Loader';
import RoomBookedSnackBar from '../Common/RoomBookedSnackBar';
import { constants } from '../../config';

function CreateTask() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Extract values using get method
    let taskID = queryParams.get('taskID');
    const statuses = constants.statuses;

    const [loading, setLoading] = useState(false);
    const [emptyField, setEmptyField] = useState('');
    const [bookedRoomNumber, setBookedRoomNumber] = useState("");

    const [formFields, setFormFields] = useState({
        task_title: '',
        task_description: '',
        assignee: '',
        project_name: '',
        status: "",
        taskID: "",
        updatedAt:""
        // Add more form fields as needed
    });
    const [empolyees, setEmpolyees] = useState([]);
    const [projects, setProjects] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);


    useEffect(() => {
        setLoading(true);
        taskID = queryParams.get('taskID');
        Axios.get(`tasks-by-id?taskID=${taskID}`)
            .then((res) => {
                setFormFields(res.data.Data[0]);
            }).catch(() => {
                console.error("Error while fetching data.");
            }).finally(()=>{
                setLoading(false);
            });

        Axios.get('employees')
            .then((res) => {
                setEmpolyees(res.data.Data);
            });

        Axios.get('projects')
            .then((res) => {
                setProjects(res.data.Data);
            });


        return () => {
        };
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormFields({
            ...formFields,
            [name]: value,
        });

        //setting empty field name to state to render error messages
        if (value === '') {
            setEmptyField(name)
        }
        else {
            setEmptyField("")
        }
    };

    const handleSubmit = (e) => {
        formFields.updatedAt = new Date().toISOString()
        // if (isObjectEmpty(formFields) === true) {
        //     let emptyFieldName = getEmptyFields(formFields)
        //     setEmptyField(emptyFieldName[0])
        // }

        if (isObjectEmpty(formFields) === false) {
            setLoading(true);

            Axios.post('update-task', formFields)
                .then((res) => {
                    openModal();

                }).catch(() => {
                    console.error("Failed to request");
                })
                .finally(() => {
                    setLoading(false);
                })

        };
        e.preventDefault();
    }

    const openModal = () => {
        setModalOpen(true);
        // Disable scrolling
        document.body.style.overflow = 'hidden';
    };


    const closeModal = () => {
        setModalOpen(false);
        // Enable scrolling
        document.body.style.overflow = 'auto';
    };

    return (<>
        {loading ? <Loader /> :
            <section id="booking">
                <RoomBookedSnackBar isModalOpen={modalOpen} closeModal={closeModal} roomNo={bookedRoomNumber} msg={"Updated"}/>
                <h2>Create Task</h2>
                <div className="status-wrapper">
                    <div className='task-status'>

                        <label htmlFor="status">Status:</label>
                        <select id="status" name="status" required value={formFields.status} onChange={handleInputChange}>
                            {/* <option value="" disabled>Status</option> */}
                            {statuses.map((value, index) => {
                                return <option key={index} value={value}>{value}</option>
                            })}
                        </select>
                    </div>
                    <div className='task-title'>
                        <label htmlFor="task_title">Task Title:</label>
                        <input type="text" id="task_title" name="task_title" required value={formFields.task_title} onChange={handleInputChange}/>
                        {emptyField === 'task_title' && <span className='error-msg'>Please Fill Task Title</span>}
                    </div>
                </div>
                <label htmlFor="task_description">Task Description:</label>
                <input type="text" id="task_description" name="task_description" required value={formFields.task_description} onChange={handleInputChange}/>
                {emptyField === 'task_description' && <span className='error-msg'>Please Fill Last Name</span>}

                <div className="status-wrapper">

                    <div className="task-assignee">

                        <label htmlFor="assignee">Assignee:</label>
                        <select id="assignee" name="assignee" required value={formFields.assignee} onChange={handleInputChange}>
                            <option value="" disabled>Select Assignee</option>
                            {empolyees.map((value, index) => {
                                return <option key={index} value={value.Name}>{value.Name}</option>
                            })}

                        </select>
                        {emptyField === 'assignee' && <span className='error-msg'>Please Select Assignee </span>}
                    </div>
                    <div className="task-project">

                        <label htmlFor="project">Project:</label>
                        <select id="project" name="project_name" required value={formFields.project_name} onChange={handleInputChange} >
                            <option value="" disabled>Select Project</option>
                            {projects.map((value, index) => {
                                return <option key={index} value={value.project_name}>{value.project_name}</option>
                            })}

                        </select>
                        {emptyField === 'project_name' && <span className='error-msg'>Please Select Assignee </span>}
                    </div>
                </div>

                {/* <label htmlFor="assignee">No of Persons:</label>
                    <select id="assignee" name="assignee" required value={formFields.assginee} onChange={handleInputChange}>
                        <option value="" disabled>Select an option</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                    {emptyField === 'assignee' && <span className='error-msg'> Assignee</span>} */}
                {/* 
                    <label htmlFor="check_in">Check-In:</label>
                    <input type="date" id="check_in" name="check_in" required value={formFields.check_in} onChange={handleInputChange} />
                    {emptyField === 'check_in' && <span className='error-msg'>Please Fill Check in Date </span>} */}

                <button onClick={handleSubmit}>update</button>
            </section >
        }
    </>)

}

export default CreateTask;