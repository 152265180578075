import React, { useEffect, useState } from "react";
import { getLeaveDateAndDurationByAssignee, getLeaveDateByAssignee } from "../../DataUtility";

function EmployeeBox({ employeeName, leavesList }) {

    let name = new Date().toDateString();
    const dateRange = [0, 1, 2, 3, , 4, 5, , 6, 7, 8, 9, 10]
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [leaveMoreThanOneDay, setLeaveMoreThanOneDay] = useState([]);

    const leavesListArray = [];

    let leaveDates = getLeaveDateAndDurationByAssignee(employeeName, leavesList);

    leaveDates.forEach(element => {

        for (let index = 0; index < element.leaveCount; index++) {
            var dateTimeList = new Date(element.leaveDate)
            dateTimeList.setDate(dateTimeList.getDate() + index);
            leavesListArray.push(dateTimeList.toLocaleDateString())
        }
    });
    console.log("empolyeeName", employeeName, "array", leavesListArray);
    return (<>
        <div className="flex-row-gap">
            <div className="date employee-cell">{employeeName}</div>
            {dateRange.map((value, index) => {

                var cellColor = "green";
                var dateTime = new Date();
                dateTime.setDate(dateTime.getDate() + value);

                let weekname = weekdays[dateTime.getDay()];

                if (leavesListArray.length != 0) {
                    if (leavesListArray.includes(dateTime.toLocaleDateString())) {

                        cellColor = "red"
                    }
                }
                if (weekname == "Sunday") {
                    cellColor = "red"
                }
                return <div key={index} className={`date status-cell ${cellColor}-cell`}>{weekname.substring(0, 3).toString()}</div>
            })}
        </div>


    </>)
}
export default EmployeeBox;