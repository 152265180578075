import React, { useState } from "react";
import { formatDate } from "../../DataUtility";
import PopUp from "../Common/PopUp";
import { constants } from "../../config";

function TaskCard({ object, handleClick, deleteItem }) {

    const [titleModalOpen, setTitleModalOpen] = useState(false);
    const [modalOpenForDescription, setModalOpenForDescription] = useState(false);
    const profilePath = constants.profilePath;
    const imagePath = `${profilePath}/${object.assignee.toLocaleLowerCase()}.jpg`;
    const placeholderImagePath = `${profilePath}/unknown.jpg`;

    const openModal = (e) => {
        let elementId = e.target.id;

        if (elementId === "title") {
            setTitleModalOpen(true);
        } else {
            setModalOpenForDescription(true);
        }
        // Disable scrolling
        document.body.style.overflow = 'hidden';
    };


    const closeModal = () => {
        setModalOpenForDescription(false);
        setTitleModalOpen(false);
        // Enable scrolling
        document.body.style.overflow = 'auto';
    };

    return (<>
        <PopUp isModalOpen={modalOpenForDescription} closeModal={closeModal} content={object.task_description} />
        <PopUp isModalOpen={titleModalOpen} closeModal={closeModal} content={object.task_title} />
        {/* <PopUp isModalOpen={modalOpen} closeModal={closeModal} content={object.status} /> */}

        <div id="roomList">
            <div className="room-card">
                <div className="profile-picture">
                    <img
                        src={imagePath}
                        onError={(e) => {
                            e.target.src = placeholderImagePath; // Replace with placeholder image
                        }}
                        alt="" />
                </div>
                {/* <p className="employee-des-pop-up" onClick={openModal}>Employee Status: <strong>{object.employeeStatus.substring(0, 15) + "..."}   </strong> </p> */}
                <p>Date: <strong> {object.CreatedAt.split("T")[0]}</strong> </p>
                <p>Assignee:  <strong> {object.assignee}</strong></p>
                <p >Status: <strong className={object.status}> {object.status}</strong></p>
                <p className="employee-des-pop-up" onClick={openModal}>Task: <strong id="title">{object.task_title.substring(0, 25) + "..."}</strong></p>
                <p className="employee-des-pop-up" onClick={openModal}>Description: <strong id="description">{object.task_description.substring(0, 20) + "..."}</strong></p>
                <p >Project: <b className={object.project_name}> {object.project_name}</b></p>
                <div className="button-wrapper">
                    <button onClick={() => { handleClick(object.taskID) }} disabled={false} className={`${false && "disabled-button"} booknow-btn`}>Update</button>
                    <button onClick={() => { deleteItem(object.taskID) }} disabled={false} className={`${false && "disabled-button"} cancel-btn`}>Delete</button>
                </div>
            </div>
        </div >



    </>)
}

export default TaskCard;