import React from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { setLoginStatus } from '../../redux/slice';
import Cookies from 'js-cookie';

function Header({logut}) {
    const dispatch = useDispatch()
    const handleLogout = ()=>{
        dispatch(setLoginStatus(false))
        Cookies.set('isLoggedIn', false)
        logut()
    }
    return (<>
        <header>
            <h1><u>Manage Project and Empolyees</u></h1>
            <button className='logout' onClick={handleLogout}>Logout</button>
            <nav>
                <ul className='menu-items'>
                    {/* <li><Link to="/booknow">Book Now</Link></li> */}
                    <li><button><Link to="/create-task">Create Task</Link></button></li>
                    <li><button><Link to="/add-daily-status">Add Daily Status</Link></button></li>
                    {/* <li><button><Link to="/new">New</Link></button></li>
                    <li><button><Link to="/pending">Pending</Link></button></li>
                    <li><button><Link to="/completed">Completed</Link></button></li> */}
                    <li><button><Link to="/employee-daily-status">Employee Status</Link></button></li>
                    <li><button><Link to="/task-filter">Task Filter</Link></button></li>
                    <li><button><Link to="/leave">Leaves</Link></button></li>
                    <li><button><Link to="/leave-plan">Leaves journey</Link></button></li>
                </ul>
            </nav>
        </header>
    </>)

}

export default Header;