import React, { useState } from "react";
import ApproveCard from "./ApproveCard";
import { Axios } from "../../config";
import Loader from "../Common/Loader";

const ApproveLeave = ({ isModalOpen, Apdata, isModalClose,update }) => {
  const [loading, setLoading] = useState(false);

  // const [modalOpen, setModalOpen] = useState(false);
  // const openModal = () => {
  //   setModalOpen(true);
  //   // Disable scrolling
  //   document.body.style.overflow = "hidden";
  // };

  // const closeModal = () => {
  //   setModalOpen(false);
  //   // Enable scrolling
  //   document.body.style.overflow = "auto";
  // };
  const handleLeaveApprove = (Id)=>{
    // openModal()
    setLoading(true)
    Axios.post('/approve-leave',{leaveId:Id}).then((res)=>{
      const status  =res.data;
      if(status.status === "success"){
        update()
      }
    }).catch((error)=>{
      console.error(error)
    }).finally(()=>{
      setLoading(false)
      // closeModal()
    })
  }
  const handleLeaveDecline = (Id)=>{
    setLoading(true)
    Axios.post('/decline-leave',{leaveId:Id}).then((res)=>{
      const status  =res.data;
      if(status.status === "success"){
        update()
      }
    }).catch((error)=>{
      console.error(error)
    }).finally(()=>{
      setLoading(false)
    })
  }
  return (
    <>
      { loading?<Loader/> : isModalOpen && (
        <div className="snack-bar-wrapper">
          <div className="modal-content-pop-up flex flex-col">
            <span className="close" onClick={isModalClose}>
              <button>Close</button>
            </span>
            <div className="flex flex-col items-center mb room-card-wrapper" >
                { Apdata && Apdata.map((leave) => (
                    <ApproveCard key={leave.Id} object={leave} handleLeaveApprove={handleLeaveApprove} handleLeaveDecline={handleLeaveDecline}/>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApproveLeave;
