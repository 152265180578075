import React from "react";
import { Link } from 'react-router-dom';

function PopUp({ isModalOpen, closeModal, content}) {

    // const [modalOpen, setModalOpen] = useState(true);

    // const openModal = () => {
    //     setModalOpen(true);
    //     // Disable scrolling
    //     document.body.style.overflow = 'hidden';
    // };


    // const closeModal = () => {
    //     setModalOpen(false);
    //     // Enable scrolling
    //     document.body.style.overflow = 'auto';
    // };
    return (<>
        {isModalOpen && <div className="snack-bar-wrapper" onClick={closeModal}>
            <div className="modal-content-pop-up">
                <span className="close" onClick={closeModal}>&times;</span>
                <p>{content}</p>
                {/* <hr />
                <Link to="/new" className="link" onClick={closeModal}><p style={{ fontSize: "15px" }} >Jump To Tasks</p></Link> */}
            </div>
        </div>
        }

    </>)
}

export default PopUp;