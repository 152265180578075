import React from "react";
import { Link } from 'react-router-dom';

function ConfirmDialogBox({ isModalOpen, closeModal, content, handleOk, handleNO}) {

    // const [modalOpen, setModalOpen] = useState(true);

    // const openModal = () => {
    //     setModalOpen(true);
    //     // Disable scrolling
    //     document.body.style.overflow = 'hidden';
    // };


    // const closeModal = () => {
    //     setModalOpen(false);
    //     // Enable scrolling
    //     document.body.style.overflow = 'auto';
    // };
    return (<>
        {isModalOpen && <div className="snack-bar-wrapper" onClick={closeModal}>
            <div className="modal-content-pop-up">
                <span className="close" onClick={closeModal}>&times;</span>
                <pre> </pre>
                <p>{content}</p>
                <hr />
                {/* <Link to="/new" className="link" onClick={closeModal}><p style={{ fontSize: "15px" }} >Jump To Tasks</p></Link> */}
                  <div className="button-wrapper">
                    <button onClick={() => { handleOk() }} disabled={false} className={`${false && "disabled-button"} confirm-btn`}>Yes</button>
                    <button onClick={() => { handleNO() }} disabled={false} className={`${false && "disabled-button"} cancel-btn`}>No</button>
                </div>
            </div>
        </div>
        }

    </>)
}

export default ConfirmDialogBox;