import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    login: localStorage.getItem('login') === 'true'||false,
    admin: localStorage.getItem('admin') === 'true'||false,
};

const ManagementSlice = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        setLoginStatus: (state, action) => {
            state.login = action.payload;
            localStorage.setItem('login', action.payload.toString());
        },
        setAdminStatus: (state, action) => {
            state.admin = action.payload;
            localStorage.setItem('admin', action.payload.toString());
        }
    }
});

const LoadingSlice = createSlice({
    name: 'loadingReducer',
    initialState: {  // Use 'initialState' instead of 'initialLoadingState'
        loading: false
    },
    reducers: {
        setLoadingStatus: (state, action) => {
            state.loading = action.payload;
        }
    }
});


export const LoadingReducer = LoadingSlice.reducer
export const {setLoadingStatus} = LoadingSlice.actions;

export const { setLoginStatus, setAdminStatus } = ManagementSlice.actions;
export default ManagementSlice.reducer;