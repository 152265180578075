import axios from "axios";

const baseUrl = window.location.protocol + '//' + window.location.host;
let isTrue = baseUrl.includes('localhost');

const API_URL = isTrue ? "http://localhost:5000/" : "https://hotel.miiscollp.com/api-v1/";

export const Axios = axios.create({ withCredentials: true, baseURL: API_URL })
export const constants = {

    profilePath: `${process.env.PUBLIC_URL}/profile`,
    statuses : ["New", "Pending", "In-Process","Completed"],

    // postImagePath: `${process.env.PUBLIC_URL}/post`,
    // profileImagepath: `${process.env.PUBLIC_URL}/profile`,
}