import React, { useEffect, useState } from "react";
import { isObjectEmpty, getEmptyFields, randomNumber } from "../../DataUtility";
import { Axios } from "../../config";
import { useLocation } from "react-router-dom";
import Loader from "../Common/Loader";
import RoomBookedSnackBar from "../Common/RoomBookedSnackBar";
import { constants } from "../../config";

function CreateTask() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Extract values using get method
  const taskID = queryParams.get("taskID");

  const statuses = constants.statuses;

  const [loading, setLoading] = useState(false);
  const [emptyField, setEmptyField] = useState("");
  const [bookedRoomNumber, setBookedRoomNumber] = useState("");

  const [formFields, setFormFields] = useState({
    taskTitle: "",
    taskDescription: "",
    assignee: "",
    project_name: "",
    status: statuses[0],
    createdAt: "",
    taskID: "",
    // Add more form fields as needed
  });
  const [empolyees, setEmpolyees] = useState([]);
  const [projects, setProjects] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    Axios.get("employees").then((res) => {
      setEmpolyees(res.data.Data);
    });

    Axios.get("projects").then((res) => {
      setProjects(res.data.Data);
    });

    return () => {};
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields({
      ...formFields,
      [name]: value,
    });

    //setting empty field name to state to render error messages
    if (value === "") {
      setEmptyField(name);
    } else {
      setEmptyField("");
    }
  };

  const handleSubmit = (e) => {
    // on submitting form check for empty fields set empty filed name to state
    formFields.taskID = randomNumber(6);
    formFields.createdAt = new Date().toISOString();
    if (isObjectEmpty(formFields) === true) {
      let emptyFieldName = getEmptyFields(formFields);
      setEmptyField(emptyFieldName[0]);
    }

    if (isObjectEmpty(formFields) === false) {
      setLoading(true);

      Axios.post("create-task", formFields)
        .then((res) => {
          // setBookedRoomNumber(res.data.room_number)
          openModal();
        })
        .catch(() => {
          console.error("Failed to request");
        })
        .finally(() => {
          // setFormFields({
          //     taskTitle: '',
          //     taskDescription: '',
          //     assginee: '',
          //     project: '',
          //     status: '',
          //     createdAt: '',
          //     // Add more form fields as needed
          // });
          setLoading(false);
        });
    }
    e.preventDefault();
  };

  const openModal = () => {
    setModalOpen(true);
    // Disable scrolling
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setModalOpen(false);
    // Enable scrolling
    document.body.style.overflow = "auto";
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section id="booking">
          <RoomBookedSnackBar
            isModalOpen={modalOpen}
            closeModal={closeModal}
            roomNo={bookedRoomNumber}
            msg={"Created"}
          />
          <h2>Create Task</h2>
          <div className="status-wrapper">
            <div className="task-status">
              <label htmlFor="status">Status:</label>
              <select
                id="status"
                name="status"
                required
                value={formFields.status}
                onChange={handleInputChange}
              >
                {/* <option value="" disabled>Status</option> */}
                {statuses.map((value, index) => {
                  return (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="task-title">
              <label htmlFor="taskTitle">Task Title:</label>
              <input
                type="text"
                id="taskTitle"
                name="taskTitle"
                required
                value={formFields.taskTitle}
                onChange={handleInputChange}
              />
              {emptyField === "taskTitle" && (
                <span className="error-msg">Please Fill Task Title</span>
              )}
            </div>
          </div>
          <div className="textarea-wrapper">
            <label htmlFor="taskDescription">Task Description:</label>
            <textarea
              id="taskDescription"
              name="taskDescription"
              required
              value={formFields.taskDescription}
              onChange={handleInputChange}
            />
            {emptyField === "taskDescription" && (
              <span className="error-msg">Please Fill Description</span>
            )}
          </div>

          <div className="status-wrapper">
            <div className="task-assignee">
              <label htmlFor="assignee">Assignee:</label>
              <select
                id="assignee"
                name="assignee"
                required
                value={formFields.assignee}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select Assignee
                </option>
                {empolyees.map((value, index) => {
                  return (
                    <option key={index} value={value.Name}>
                      {value.Name}
                    </option>
                  );
                })}
              </select>
              {emptyField === "assignee" && (
                <span className="error-msg">Please Select Assignee </span>
              )}
            </div>
            <div className="task-project">
              <label htmlFor="project">Project:</label>
              <select
                id="project"
                name="project_name"
                required
                value={formFields.project_name}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select Project
                </option>
                {projects.map((value, index) => {
                  return (
                    <option key={index} value={value.project_name}>
                      {value.project_name}
                    </option>
                  );
                })}
              </select>
              {emptyField === "project" && (
                <span className="error-msg">Please Select Assignee </span>
              )}
            </div>
          </div>

          {/* <label htmlFor="assignee">No of Persons:</label>
                    <select id="assignee" name="assignee" required value={formFields.assginee} onChange={handleInputChange}>
                        <option value="" disabled>Select an option</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                    {emptyField === 'assignee' && <span className='error-msg'> Assignee</span>} */}
          {/* 
                    <label htmlFor="check_in">Check-In:</label>
                    <input type="date" id="check_in" name="check_in" required value={formFields.check_in} onChange={handleInputChange} />
                    {emptyField === 'check_in' && <span className='error-msg'>Please Fill Check in Date </span>} */}

          <button onClick={handleSubmit}>Create</button>
        </section>
      )}
    </>
  );
}

export default CreateTask;
