import React from 'react'

function Footer() {

    return (<>
        <footer>
            <p>&copy; {new Date().getFullYear()} Miisco LLP. All rights reserved.</p>
        </footer>
    </>)

}

export default Footer;