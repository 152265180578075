import React, { useState } from "react";
import { constants } from "../../config";
import { convertDateFormat } from "../../DataUtility";

const ApproveCard = ({ object ,handleLeaveApprove,handleLeaveDecline}) => {
  const profilePath = constants.profilePath;
  const imagePath = `${profilePath}/${object.assignee.toLocaleLowerCase()}.jpg`;
  const placeholderImagePath = `${profilePath}/unknown.jpg`;

 
  return (
      <div className="room-card">
        <div className="approve-prof_p">
          <img
            src={imagePath}
            onError={(e) => {
              e.target.src = placeholderImagePath; // Replace with placeholder image
            }}
            alt=""
          />
        </div>
        <p>
          Date: <strong> {convertDateFormat(object.leaveDate)}</strong>{" "}
        </p>
        <p>
          Employee: <strong> {object.assignee}</strong>
        </p>
        <p>
          No of Days: <strong> {object.leaveDuration}</strong>
        </p>
        <p className="employee-des-pop-up">
          Status:{" "}
          <strong
            className={object.status === "Approved" ? "Completed" : "New"}
          >
            {object.status.substring(0, 15) + "..."}{" "}
          </strong>{" "}
        </p>
        {/* <p>Task: <strong>{object.task_title}</strong></p>
            <p >Project: <b className={object.project_name}> {object.project_name}</b></p>
            <p >Status: <strong className={object.status}> {object.status}</strong></p> */}
        <div className="button-wrapper">
          <button
            onClick={() => {
              handleLeaveApprove(object.Id);
            }}
            disabled={false}
            className={`${false && "disabled-button"} booknow-btn`}
          >
            Approve
          </button>
          <button
            onClick={() => {
              handleLeaveDecline(object.Id);
            }}
            disabled={false}
            className={`${false && "disabled-button"} cancel-btn`}
          >
            Decline
          </button>
        </div>
      </div>
  );
};

export default ApproveCard;