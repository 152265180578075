import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Components/Common/Header';
import Footer from './Components/Common/Footer';
import CreateTask from './Components/CreateTask/CreateTask';
// import TaskByDay from './Components/TaskByDay/TaskByDay';
// import Pending from './Components/Pending/Pending';
// import Completed from './Components/CreateTask/Completed';
import Login from './Components/Common/Login';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Update from './Components/CreateTask/Update';
import TaskFilter from './Components/TaskFilter/TaskFilter';
import EmployeeStatus from './Components/TaskFilter/EmployeeStatus';
import AddDailyStatus from './Components/CreateTask/AddDailyStatus';
import Welcome from './Components/TaskByDay/Welcome';
import LeaveStatus from './Components/Leave/LeaveStatus';
import {  useDispatch } from 'react-redux'
import { setAdminStatus,setLoginStatus } from './redux/slice';
import LeavePalns from './Components/Leave/LeavePlans';

function App() {

  // const [isLoggedIn, setLoggedIn] = useState(false);
  // const [isLoggedIn, setLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [isLoggedIn, setLoggedIn] = useState(Cookies.get('isLoggedIn') === 'true');
  const dispatch = useDispatch();
  
const logut = ()=>{
  setLoggedIn(false)
}

  // useEffect(() => {
  //   // Use effect to update local storage when isLoggedIn changes
  //   localStorage.setItem('isLoggedIn', isLoggedIn);
  // }, [isLoggedIn]);

  useEffect(() => {
    // Use effect to update cookies when isLoggedIn changes
    Cookies.set('isLoggedIn', isLoggedIn, {
      // expires: 43200 / (24 * 60 * 60)
      expires:3
    });
  }, [isLoggedIn]);



  const CheckLogin = (status) => {
    dispatch(setLoginStatus(status.status));
    dispatch(setAdminStatus(status.isAdmin));
    setLoggedIn(status.status);
  };

  if (isLoggedIn === false) {
    return (
      <>
        <Login CheckLogin={CheckLogin} />
      </>)
  }

  return (
    <Router>
      <Header logut={logut}/>
      <Routes>
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/create-task" element={<CreateTask />} />
        {/* <Route path="/new" element={<TaskByDay />} />
        <Route path="/pending" element={<Pending />} />
        <Route path="/completed" element={<Completed />} /> */}
        <Route path="/" element={<Welcome />} />
        <Route path="/update" element={<Update />} />
        <Route path="/task-filter" element={<TaskFilter/>} />
        <Route path="/employee-daily-status" element={<EmployeeStatus />} />
        <Route path="/add-daily-status" element={<AddDailyStatus />} />
        <Route path="/leave" element={<LeaveStatus />} />
        <Route path="/leave-plan" element={<LeavePalns />} />
      </Routes>
      <Footer />
    </Router>
  );
};


export default App;
