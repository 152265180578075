import React, { useRef, useState } from "react";
import { Axios } from "../../config";
import Loader from "./Loader";
import { isObjectEmpty } from "../../DataUtility";
import { useEffect } from "react";

function Login({ CheckLogin }) {
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
    // Add more form fields as needed
  });

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const [isLoginDisabled, setIsLoginDisabled] = useState(true);

  useEffect(() => {

    if (usernameRef.current) {
      usernameRef.current.focus();
    }
    
  }, []);

  //Event OnpressEnter for login button
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
    e.preventDefault();
  };

const enableLogin = ()=>{
  console.log("valuelajldjlfjalkfafljalkdfjalkdjfa", usernameRef.current.value);

}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });

    setIsLoginDisabled(passwordRef.current.value == "")

    // Clear error for the field being edited
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleOnLoad = (e) => {
    const { name, value } = e.target;
    loginDetails.username = document.getElementById("username");
    loginDetails.password = document.getElementById("password")

    console.log(loginDetails);
    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });

    // Clear error for the field being edited
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  //   useEffect(() => {
  //     setLoginError(false);
  //   }, []);

  // Event handler to toggle the checkbox state
  const handleCheck = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isObjectEmpty(loginDetails) === false) {
      setLoading(true);
      Axios.post("login", loginDetails)
        .then((res) => {
          if (res.data.status) {
            CheckLogin(res.data);
          } else {
            setErrors({ server: "Incorrect username or password." });
          }
        })
        .catch(() => {
          setLoading(false);
          setErrors({ server: "An error occurred. Please try again later." });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setErrors({
        username: loginDetails.username ? "" : "Username cannot be empty.",
        password: loginDetails.password ? "" : "Password cannot be empty.",
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section>
          <h1 style={{ textAlign: "center" }}>Login</h1>
          <form >
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              required
              ref={usernameRef}
              value={loginDetails.username}
              onChange={handleInputChange}
            />
            {errors.username && (
              <span className="error-msg">{errors.username}</span>
            )}

            <label htmlFor="password">Password:</label>
            <input
              type={`${isChecked ? "text" : "password"}`}
              id="password"
              name="password"
              required
              ref={passwordRef}
              value={loginDetails.password}
              onChange={handleInputChange}
              onInput={handleInputChange}
            />
            {errors.password && (
              <span className="error-msg">Password cannot be empty</span>
            )}

            {/* <input type="checkbox" name="showPassword" onChange={handleInputChange} /> */}
            <div className="showPassword">
              <p onClick={handleCheck}>
                {isChecked ? "Hide" : "Show"} Password
              </p>
            </div>
            {errors.server && (
              <span className="error-msg">{errors.server}</span>
            )}
            <button
              type="submit"
              disabled={isLoginDisabled}
              onClick={isLoginDisabled ? enableLogin : handleSubmit}
              className={`${isLoginDisabled && "disabled-button"}`}
            >
              Login
            </button>
          </form>
        </section>
      )}
    </>
  );
}

export default Login;
