import React from "react";

function Loader() {
    return (<>
        <div className="loader-wrapper">

            <span className="loader"></span>
        </div>
    </>)
}

export default Loader;