import React from "react";

function DateBox({lastDate}) {
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    var dateTime = new Date();

    dateTime.setDate(dateTime.getDate() + lastDate);
    var date = dateTime.getDate();
    var month = monthNames[dateTime.getMonth()];
    
    return (<>
        <div className="date ">
            <span>{date}, </span>
            <span>{month}</span>

        </div>
    </>)
}

export default DateBox;